body.print-mode .sidebar,
body.print-mode .navbar,
body.print-mode .footer
{
    display: none;
}

body.print-mode .main-panel {
    width: 100% !important;
}


body.print-mode .main-panel .main-panel-container,
body.print-mode .main-panel .main-panel-container .main-panel-container-content
{
    padding: 0 !important;
}

body.print-mode .main-panel-container-content > div {
    border-radius: 0 !important;
}

body.print-mode .partnership {
    display: none;
}

.map-drawer-btn {
    position: fixed;
    left: 0;
    bottom: 60px;
}


.partnership {
    position: fixed;
    right: 0;
    bottom: 50px;
}

.partnership > div, .map-drawer-btn > div, .map-legend-container {
    padding: 5px;
    background-color: white;
    border-radius: 10px 0px 0px 10px;
    border: 1px solid var(--chakra-colors-blue-300);
    border-right-width: 0;
    box-shadow: 0px 0px 5px #888;
    font-weight: 600;
    transition: padding .25s;
}

.map-drawer-btn > div {
    border-radius: 0px 10px 10px 0px;
}


.map-drawer-btn > div:hover {
    padding-left: 15px;
}

.partnership > div:hover{
    padding-right: 15px;
}

.chakra-ui-dark .partnership > div, .chakra-ui-dark .map-drawer-btn > div, .chakra-ui-dark .map-legend-container {
    background-color: var(--chakra-colors-gray-700);
    box-shadow: 0px 0px 5px #555;
    border-color: var(--chakra-colors-blue-700)
}

.icone {
    background-color: white;
    border-radius: 25px;
    text-align: center;
    height: 20px;
    width: auto;
    white-space: nowrap;
    display: inline-block;
    padding: 2px 8px;
    font-weight: 600;
    margin-left: -15px;
}

.icone-cluster {
    height: auto;
    line-height: 12px;
    padding: 4px 10px;
}

.leaflet-div-icon {
    border: none !important;
}

.icone-9EC5E1, .icone-FFFFFF, .icone-FEF102, .icone-DDDDDD {
    color: #333 !important;
}

.theme-bg {
    background-color: white;
}

.chakra-ui-dark .theme-bg {
    background-color: var(--chakra-colors-gray-700);
}
