.pagination {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    gap: 5px;
}

.page-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    background-color: white;
    border-radius: 5px;
    color: rgb(26, 32, 44);
    cursor: pointer;
    font-weight: semibold;
    border: 1px solid #e2e8f0;
}

.page-link:hover {
    background-color: #edf2f7;
}

.page-item.active .page-link {
    background-color: #3182ce;
    color: #fff;
}

.page-item.active .page-link:hover {
    background-color: #2b6cb0;
}

.chakra-ui-dark .page-link {
    border-color: rgba(255, 255, 255, .16);
    color: rgba(255, 255, 255, .92);
    background: transparent;
}

.chakra-ui-dark .page-item.active .page-link {
    background-color: #63b3ed;
    color: #1a202c;
}
